body {
  overflow: hidden;
  font-family: Outfit;
}

.app{
  margin: 0;
  padding: 0;
  background-image: url('images/background.jpg'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  position: fixed; 
  height: 100vh;
  width: 100vw;
}

.Landing {
  text-align: center;
  height: 100vh;
  background-color: rgb(0,19,53);
  overflow: hidden;
}

model-viewer {
  width: 100vw;
  height: 100vh;
  display: block;
}

.view-button {
  background: #fff;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 12px;
  font-weight: 700;
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  width: max-content;
  height: max-content;
  transform: translate3d(-50%, -50%, 0);
}

.Hotspot {
  background: #fff;
  border-radius: 32px;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  cursor: pointer;
  height: 24px;
  padding: 8px;
  position: relative;
  transition: opacity 0.3s;
  width: 24px;
}


.Hotspot:focus {
  border: 4px solid rgb(0, 128, 200);
  height: 32px;
  outline: none;
  width: 32px;
}

.HotspotAnnotation{
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 18px;
  font-weight: 700;
  left: calc(100% + 1em);
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  top: 50%;
  width: max-content;
}

.Hotspot:not([data-visible]) > * {
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(-50% + 4px));
  transition: transform 0.3s, opacity 0.3s;
}


.ticketPage{
  margin: 0;
  padding: 0;
  background-image: url('images/background.jpg'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  position: fixed; 
  height: 100vh;
  width: 100vw;
}

.navBar{
  background: rgb(19,91,156);
  background: linear-gradient(90deg, rgba(19,91,156,1) 37%, rgba(121,191,215,1) 81%);

}
.css-19kzrtu{
  padding: 0 !important;
}

.logo-logged-in{
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  margin-top: 2%;
}

a{
  text-decoration: none;
  color: white;
}

img {
  max-width: 100%; 
  max-height: 100%; 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.chat{
  position: absolute;
  bottom: 8.5%;
  right: 8%;
  background-color: transparent;
  color: rgb(100,234,255);
  border-radius: 20px;
  z-index: 250000;
  font-family: Outfit;
}


.App-link {
  color: #61dafb;
}

.header{
  text-align: center;
}

.card{
  margin: 5%;
  text-align: center;
  padding: 2%;
  border: #282c34 1px solid;
  box-shadow: 5px 5px 0px 0px black ;
  background-color: lightgray;
  font-size: large;
  border-radius: 25px;
}

.loader{
  background-color: rgb(0,19,53);
}
.centre{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.unity{
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: white;
  cursor: default !important;
}

.controlBtns{
  position: absolute;
  bottom:1%;
  right: 4%;
  z-index: 250000;
}

.unityBtn{
  padding: 30%;
  background-color: rgba(34, 36, 48, 0.7);
  color: rgb(100,234,255);
  border: 2px white dotted;
  border-radius: 50%;
  margin: 10px;
  display: flex; 
  justify-content: center; 
  align-items: center;
  cursor: pointer;
  z-index: 250000;
}

.unityBtn:hover{
  background-color: rgb(100,234,255);
  color: rgb(34, 36, 48);
}


ul{
  list-style-type: none;
}
.chatText{
  color: rgb(100,234,255);
  text-align: center;
  font-family: Outfit;
}
#outlined-basic{
  color: white;
}
.css-1x5jdmq{
  color: white !important;
}

.chat-container{
  width: 98vw;
  overflow-y: scroll;
  text-align: left;
  height: 80vh;
  margin: 0;
}


.chat-bubble{
  padding: 2%;
  margin: 3%;
  text-decoration: none;
  text-align: left;
  width: 80%;
  margin-right: auto;
  border-radius: 5px;
  color: white;
  word-wrap: break-word; 
  white-space: normal;
}

.bubble-timestamp{
  margin-right: auto;
  color: white;
}

.user-chat-bubble{
  background-color:#baffc9;
  width: 80%;
  color: black;
  padding: 2%;
  margin: 1%;
  text-decoration: none;
  margin-right: auto;
  border-radius: 5px;
  word-wrap: break-word; 
  white-space: normal;
}
.user-bubble-timestamp{
  color: white;
  position: relative;
}

.chat-input{
  display: flex;
  align-items: center;
  margin: 1% auto;
  width: 80%;
}

.emoji-button {
  margin-left: 5px; 
  font-size: 240%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.emoji-picker{
  position: absolute;
  top: 5%;
  right: 10%;
}

.chat-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


#name-input{
  color: black !important;
}

.avatar-container{
  padding-top: 300px;  
  display: flex;
}

.avatar-card{
  background-color: #bae1ff;
  padding: 100px;
  width: 30%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
}
.footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}

.centered-div{
  position: absolute;
  top: 50%;
  left: 50%;
}

.circle{
  border: solid rgb(100,234,255) 10px;
  box-shadow: #61dafb 10px;
  border-radius: 50%;
  box-shadow: 
  0 0 35px 35px rgba(0, 255, 255, 0.3), 
  inset 0 0 35px 35px rgba(0, 255, 255, 0.3); 
}

.dotted{
  border: dotted rgb(100,234,255) 10px;
  box-shadow: #61dafb 10px;
  border-radius: 50%;
  pointer-events: none;
}

.absolute{
  position: absolute;
  top: 50%;
  left: 50%;
}

.offset{
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%) 
}

.spring-div{
  border: dotted rgb(100,234,255) 10px;
  box-shadow: #61dafb 10px;
  position: absolute;
  top: 40%;
  left: 40%;
  border-radius: 50%;
  pointer-events: none;
}

.circle1{
  transform: translate(-50%, -50%)
}
.shape1{
  position: relative;
  left: 50.8%;
  top: 38%;
  cursor: pointer;
}
.shape2{
  position: relative;
  left: -50.5%;
  top: 43%;
  z-index: 2000;
  cursor: pointer;
}
.shape3{
  position: relative;
  left: 50.3%;
  top: 45%;
  z-index: 2000;
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center; 
  width: 100%;
  height: 100vh;
}

.centre-image {
  position: fixed;
  top: -3%;
  pointer-events: none;
}


.input-container {
  position: absolute;
  top: 75%; 
  left: 0; 
  width: 100%;
  height: auto; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  z-index: 1; 
}


.button {
  width: 15%; 
  position: absolute;
  left: 50%;
  top: 73%; 
  transform: translate(-50%, -50%);
}


@keyframes circle-closkwise-spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes circle-anticlockwise-spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .circle2{
    animation: circle-anticlockwise-spin infinite 30s linear;
  }
  .circle3{
    animation: circle-closkwise-spin infinite 25s linear;
  }
  .dot1{
    animation: circle-anticlockwise-spin infinite 90s linear;
  }
  .dot2{
    animation: circle-closkwise-spin infinite 75s linear;
  }
}

.avatar-background{
  background: rgb(6,46,170);
  background: linear-gradient(0deg, rgba(6,46,170,1) 5%, rgba(0,19,53,1) 35%);
  height: 100vh;
  width: 100vw;
}

.avatar-logo{
  position: absolute;
  left: 30%;
  top: 30%;
}

.avatar-input-area1{
  z-index: 10000;
  color: white;
  font-size: 2.2vh;
  position: absolute;
  left:70%;
  top: 10%;
}

.avatar-square1{
  z-index: 10000;
  position: absolute;
  top: 53%;
  left: 117%;
  width: 8%;
  height: 8%;
}

.avatar-input-area2{
  z-index: 10000;
  color: white;
  font-size: 2.2vh;
  position: absolute;
  left: 70%;
  top: 50%;
}

.avatar-square2{
  z-index: 1500000;
  position: absolute;
  top: -5%;
  left: 97%;
  width: 8%;
  height: 8%;
  transform: rotate(-45deg);
}

.custom-input {
  padding: 0.5rem 1.5rem;
  border: double 2px transparent;
  border-radius: 50px;
  background-image: linear-gradient(rgba(0,19,53,1), rgba(0,19,53,1)), 
  linear-gradient(90deg, rgba(205,224,250,1) 0%, rgba(34,80,229,1) 55%, rgba(117,6,222,1) 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: rgba(0,19,53,1);
  color: white;
  margin-bottom: 20px;
  width: 100%;
}

.custom-input::placeholder {
  color: rgb(66, 105, 233);
}

.chat-input{
  padding: 0.5rem 1.5rem;
  border: dotted 2px rgb(100,234,255);
  border-radius: 50px;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: rgba(0,19,53,1);
  color: white;
  margin-bottom: 20px;
  width: 70%;
}

#chat-input{
  border: none;
  background-color: transparent;
  width: 90%;
  height: 100%;
  color: white;
  font-size: larger;
}

#chat-input:focus {
  outline: none; 
}

.customButton{
  margin-left: 5%;
  width: 100%;
}

.avatar-display{
  height: 100vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: -18%;
}

.send-button{
  color: #61dafb;
  border: none;
  background-color: transparent;
}